@import '../../Styles/Vars.scss';
@import '../../Styles/Mixins.scss';

#archive{
  z-index: 998;
}

.react-select-container{
  z-index: 999999;
}

.archive--container {
    position: relative;
    text-align: left;
    width: 100%;
    pointer-events: auto;
    overflow: hidden;
    background-color: rgba(0,0,0,.75);

    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 1px;

    @media only screen and (max-width: 1050px) {
      flex-direction: column;
      padding: 20px 0px 20px 0px;  
    }

    p {
      color: #fff;
      padding: 0 auto;
      font-size: 13px;
      line-height: 1.6em;
      letter-spacing: .05em;
      font-family: $verdana;
      opacity: .9;
      text-align: left;
      margin: 0px auto;
      @media only screen and (max-width: 1050px) {
        font-size: 13px;
      }
    }

    h1 {
      font-size: 36px;
      color: $h1-color;
      margin: 0;
      font-family: $franklin;
      font-weight: 900;
      letter-spacing: -1px;
      @media only screen and (max-width: 1050px) {
        font-size: 48px;
      }
      @media only screen and (max-width: 568px) {
        font-size: 48px;
      }
      @media only screen and (max-width: 320px) {
        font-size: 36px;
      }
    }
}

.archive--details{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  @media only screen and (max-width: 1050px) {
    align-items: left;
    display: flex;
    flex-direction: column;
  }
}

.count--container{
  padding: 33px;
  position: relative;
  @media only screen and (max-width: 1050px) {
    padding: 0px;
    position: absolute;
    z-index: 0;
    opacity: .05;
  }
}

.copy--container{
  padding: 25px 0px;
  z-index: 1;
  width: 100%;
  @media only screen and (max-width: 1050px) {
    width: calc(100% - 40px);
    
  }
}

.link--container{
  padding: 0px 85px 0px 0px;
  z-index: 1;
  @media only screen and (max-width: 1050px) {
    padding: 20px 0px 20px 0px;
  }
}


.archive--single{
  opacity: 1;
  width: 100%;
}

.archive--count{
  display: block;
  float: left;
  margin: 0px;
  padding: 0px;
  width: 100px;
  p{
    font-family: dita, sans-serif;
    font-weight: 900;
    font-size: 82px;
    text-align: center;
    @media only screen and (max-width: 1050px) {
      font-size: 600px;
      letter-spacing: -30px;
    }
  }
  @media only screen and (max-width: 1050px) {
    margin-top: -320px;
    margin-left: -530px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: -320px;
    margin-left: -280px;
  }
}

.archive--header{
  display: block;
  float: left;
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
}

.archive--paragraph{
  display: block;
  float: left;
  margin: 0px;
  padding: 0px;
  width: 80%;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
}

.archive--type {
    margin: 0px auto;
    padding: 0px auto;
    float: left;
    position: relative;
    right: 0;
    z-index: 1;
    border-bottom: 1px solid white;
    h3 {
      font-size: 10px;
      color: white;
      font-family: $montserrat;
      font-weight: 300;
      padding: 0px 0px 0px;
      letter-spacing: .6px;
      margin: 0px;
      margin-bottom: 2px;
    }
}

.archive--details{
    position: relative;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: auto;
}

.header--boarder {
    margin: 0px auto;
    text-align: center;
    padding: 0 auto;
    width: 100%;
    height: 4px;
    background-image: url(../../Images/portBoarder.png);
}

.archive--link {
  margin-bottom: 20px;
  margin-top: 20px;
  @include allScale(1);

  a{
    color: #fff;
    text-decoration: none;
    font-family: $verdana;
    font-weight: 600;
    font-size: 1em;
    letter-spacing: .01em;
    background-color: none;
    background-image: url(../../Images/linkBg.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 15px 34px 15px 35px;
    
    @include allTrans();
    &:hover{
      opacity: .8;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
    margin-top: 20px;
    @include allScale(0.7);
  } 
}

#archive--container{
  position: relative;
  text-align: center;
  margin: 0px auto;
  z-index: 999;
  width: 100%;
  height: 70px;
  background-color: $base-color;

  input{
    height: 66px;
  }
}

.archive--100{
  width: 100%;
  text-align: center;
}

.react-select__single-value{
  margin-left: 35px !important;
  margin-right: 0px !important;
}
