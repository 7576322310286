@import '../../Styles/Vars.scss';
@import '../../Styles/Mixins.scss';

#about{
    position: relative;
    width: 100%;
    height: auto;
    margin: 0px auto;
    padding: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    padding-bottom: 100px;
    overflow: hidden;
    h1{
        padding: 0px;
        font-size: 180px;
        color: #08ffff;
        font-family: $bree;
        margin: 0px;
        @media only screen and (max-width: 768px) {
            font-size: 90px;
          }
    }
    p, .about--roll{
        font-size: 23px;
        color: white;
        border: 0px;
        line-height: 42px;
        font-family: $franklin;
        font-weight: 700;
        @media only screen and (max-width: 1050px) {
            font-size: 21px;
            line-height: 36px;
          }
        @media only screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 28px;
          }
    }

    a{
        color: white;
    }
}

#about--container{
    text-align: center;
    position: relative;
    margin: 0 auto;
    padding: 0 auto;
    width: 80%;
    z-index: 10;
    @media only screen and (max-width: 1050px) {
        width: 80%;
      }
    @media only screen and (max-width: 768px) {
        width: 80%;
      }
}

.about--roll{
    background: red;
    padding: 5px 15px;
    @include noselect();
    @include allTrans();
    @media only screen and (max-width: 1050px) {
        padding: 5px;
      }
    @media only screen and (max-width: 768px) {
        padding: 2px;
      }

    &:hover{
        cursor: help;
        background-color: $red-color-hover;
    }
}

#about--roll--container{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: .4;
}

#about--gif{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}