@import '../../Styles/Vars.scss';
@import '../../Styles/Mixins.scss';

#footer{
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
}

#footer-detail{
    margin: 0 auto;
    text-align: center;
    margin-top: -10px;
    padding-bottom: 10px;
    background-color: rgb(41, 18, 214);
    .scroll-to-link{
        position: relative;
        top: 0px;
    }
}

.scroll-to-link{
    font-family: $bree;
    font-size: 32px;
    letter-spacing: .005em;
    color: white;
    font-weight: 900;
    text-align: center;
    background-color: red;
    padding: 0px 20px 0px;
    margin: 0 auto;
    border-radius: 0px 0px 10px 10px;
    @include allTrans();
    &:hover{
        background-color: $red-color-hover;
    }
}

.scroll-to-link:hover{
    cursor: pointer;
}