@import '../../Styles/Vars.scss';
@import '../../Styles/Mixins.scss';

#ticker--container{
    width: 100%;
    overflow: hidden;

    #ticker-content-cotainer{
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        text-transform: uppercase;
    }

    .ticker-animation{
        animation: moveSlideshow 40s linear infinite;
        animation-delay: 0;
        will-change: auto;
    }

    .ticker-animation1{
        animation: moveSlideshow 39s linear infinite;
        animation-delay: 0;
        will-change: auto;
    }

    .ticker-animation2{
        animation: moveSlideshow 41s linear infinite;
        animation-delay: 0;
        will-change: auto;
    }

    .ticker-animation3{
        animation: moveSlideshow 37s linear infinite;
        animation-delay: 0;
        will-change: auto;
    }

    .ticker-animation4{
        animation: moveSlideshow 44s linear infinite;
        animation-delay: 0;
        will-change: auto;
    }

    .ticker-animation5{
        animation: moveSlideshow 34s linear infinite;
        animation-delay: 0;
        will-change: auto;
    }

    .ticker-content{
        will-change: transform;
        padding: 15px;
        margin-left: -0%;
    }

    h1 {
        opacity: .8;
        font-size: 82px;
        color: white;
        margin: 0;
        font-family: $dita;
        font-weight: 800;
        font-style: italic;
        letter-spacing: -1px;
        white-space: nowrap;
        @include noselect();
    }

    p{
        font-family: $franklin;
        line-height: 5px;
        white-space: nowrap;
        color: white;
        text-align: center;
        opacity: .75;
        @include noselect();
    }
    img{
        height: 100px;
    }
}

@keyframes moveSlideshow {
    0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(calc(-50%), 0);
      }
  }