/* color styles */

$base-color: black;
$bg-color: #cccccc;

$red-color: red;
$red-color-hover: rgb(202, 2, 2);

$green: #80f94d;

$h1-color: #08ffff;


/* font styles */

$bree: "bree-serif",sans-serif;
$franklin: "Franklin Gothic Medium","Franklin Gothic","ITC Franklin Gothic",Arial,sans-serif;
$verdana: Verdana,Geneva,sans-serif;
$montserrat: "Montserrat",Verdana,Arial,sans-serif;
$dita: dita, sans-serif;