@import '../../Styles/Vars.scss';

#viewcounter-container{
    position: fixed;
    right: 20px;
    top: 20px;
    width: 150px;
    height: 150px;
    z-index: 999999;
    
    p{
        position: absolute;
        width: 100%;
        font-family: $bree;
        font-size: 14px;
        color: white;
        top: 28px;
        text-align: center;
        z-index: 1;
    }

    h1{
        position: absolute;
        width: 100%;
        font-family: $dita;
        color: white;
        top: 35px;
        font-size: 42px;
        text-align: center;
        z-index: 1;
    }

    .burst{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        -webkit-animation: rotating 20s linear infinite;
        -moz-animation: rotating 20s linear infinite;
        -ms-animation: rotating 20s linear infinite;
        -o-animation: rotating 20s linear infinite;
        animation: rotating 20s linear infinite;
    }
}


@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }