@import './Styles/Vars.scss';

.hidden { 
   display:none;

}

.visible { 
   display: block; 

}

.App {
  text-align: center;
}

.App-logo {
  width: 40%;
  @media only screen and (max-width: 1280px) {
		width: 50%;
	}
  @media only screen and (max-width: 768px) {
		width: 70%;
	}

}

.App-header {
  background-color: transparent;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $base-color
}

.App-link {
  color: $base-color;
}

#oneDiv{
  display: none;
}

.unselectable{
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}