@import '../../Styles/Vars.scss';
@import '../../Styles/Mixins.scss';

#projects{
  z-index: 998;
}

.project--container {
    padding: 0px;
    margin: 0px;
    position: relative;
    text-align: center;
    background-position: center center;
    // height: 600px;
    width: 100%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    backface-visibility: hidden;
    pointer-events: auto;
    overflow: hidden;
    @media only screen and (max-width: 768px) {

    }
    @media only screen and (max-width: 568px) {
      
    }
    @media only screen and (max-width: 320px) {
      
    }

    p {
      color: #fff;
      padding: 0 auto;
      font-size: 16px;
      line-height: 1.6em;
      letter-spacing: .05em;
      font-family: $verdana;
      opacity: .9;
      width: 80%;
      text-align: center;
      margin: 0px auto;
      margin-top: 20px;
      @media only screen and (max-width: 768px) {
        
      }
      @media only screen and (max-width: 568px) {
        font-size: 14px;
        width: 90%;
      }
      @media only screen and (max-width: 320px) {
        font-size: 14px;
        width: 95%;
      }
    }

    h1 {
      font-size: 62px;
      color: $h1-color;
      padding-bottom: 10px;
      margin: 0;
      font-family: $franklin;
      font-weight: 900;
      letter-spacing: -1px;
      @media only screen and (max-width: 768px) {
        font-size: 54px;
      }
      @media only screen and (max-width: 568px) {
        font-size: 36px;
      }
      @media only screen and (max-width: 320px) {
        font-size: 24px;
      }
    }
}

.bigger{
  font-size: 940px;
  position: absolute;
  top: -400px;
  left: 0px;
  font-family: dita, sans-serif;
  font-weight: 700;
  font-style: italic;  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.04;
  color: white;
  letter-spacing: -70px;
  overflow: hidden;
  width: 100%;
  text-align: left;
    .bigger--count{
      position: relative;
      top: 110px;
      left: -150px;
    }
}

.project--single{
  opacity: 1;
  width: 100%;
}

.project--type {
    margin: 0px auto;
    padding: 0px auto;
    width: 110px;
    float: right;
    background-color: #ffe600;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    right: 0;
    z-index: 1;
    h3 {
      font-size: 10px;
      color: #6b6b6b;
      font-family: $montserrat;
      font-weight: 500;
      padding: 10px 10px 10px;
      letter-spacing: .6px;
      text-align: center;
    }
}

.project--details{
    position: relative;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    padding: 50px 0px 50px;
    margin: 75px 0px;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: auto;
    -webkit-transition: all 2s ease-out;
    -moz-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;

    @media only screen and (max-width: 768px) {
      padding: 0px;
    }
    @media only screen and (max-width: 568px) {
      padding: 0px;
    }
    @media only screen and (max-width: 320px) {
    }
}


.header--boarder {
    margin: 0px auto;
    text-align: center;
    padding: 0 auto;
    width: 85%;
    height: 4px;
    background-image: url(../../Images/portBoarder.png);
}

.proj--link--list {
  margin-top: 40px;
  a{
    color: #fff;
    text-decoration: none;
    font-family: $verdana;
    font-weight: 600;
    font-size: 1em;
    letter-spacing: .01em;
    background-color: none;
    background-image: url(../../Images/linkBg.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 15px 34px 15px 35px;
    @include allTrans();
    &:hover{
      opacity: .8;
    }
  }
}

.project--hover--set{
  width: 0%;
  height: 0%;
}